/*
 * Application-wide styles
 */

.icon {
  margin: 3px 0 0 0;
}

.icon.text-success {
  fill: #28a745;
}

.btn-outline-primary .icon {
  fill: #007bff;
}
.btn-outline-secondary .icon {
  fill: #6c757d;
}
.btn-outline-primary:hover .icon,
.btn-outline-secondary:hover .icon {
  fill: #ffffff;
}

/* TCF brand blue */
.bg-dark {
  background-color: #000033 !important;
}

.react-pdf__Page canvas {
  border: 1px #6c757d solid;
  box-sizing: border-box;
  margin-bottom: 1rem;
}

.redux-toastr .top-center {
  min-width: 50vw;
}

/* So we can nest <Button><Link to=""/></Button> */
.btn a {
  color: inherit;
}

/* react-vis legend */
.rv-xy-plot {
  margin-bottom: 50px;
}

/* react-widgets multiselect */
.rw-multiselect-tag > *,
.rw-list-option {
  font-size: 80%;
}

.rw-multiselect-tag {
  margin-top: 0;
  height: auto;
}

.tcf-rte-editor {
  min-height: 300px !important;
  max-height: 75vh !important;
  overflow: scroll !important;
}

/* Adding this allows the user to click anywhere in the rich text box to focus the editor.  Before they had to
   click the top row which I found annoying. */
.DraftEditor-root,
.DraftEditor-editorContainer,
.public-DraftEditor-content {
  min-height: inherit;
}

.btn {
  margin-bottom: 0.5rem;
}

@media screen {
  #copyright {
    display: none;
  }
  .app {
    display: block !important;
  }
}

@media print {
  #copyright {
    display: block;
  }
  .app {
    display: none !important;
  }
}

/* Prevent users from selecting text.  Not even a little bit foolproof. */
.noselect {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.clickable {
  cursor: pointer;
}

.directory-entity .table {
  border-bottom: 1px solid #dee2e6;
}

.directory-entity .table th,
.directory-entity .table td {
  font-size: 0.9em;
  font-weight: normal;
  padding: 0.3em;
}

.directory-entity .table th {
  background-color: #999;
  color: white;
}

.directory-entity .table tr:hover :not(th) {
  background-color: #f9f9ff;
}

.tableButton {
  font-size: 0.75rem;
  margin: 0 0 0 0.75rem;
  padding: 0;
}

.profile-picture-upload {
  display: inline-block;
  position: relative;
  padding: 6px;
  cursor: pointer;
  margin-bottom: 25px;
}

.profile-picture-upload .img-wrap {
  position: relative;
  width: 200px;
  height: 200px;
  overflow: hidden;
}

.profile-picture-upload .img-upload:hover:before {
  opacity: 1;
}

.profile-picture-upload img {
  width: auto;
  height: 100%;
}

.profile-picture-upload input[type='file'] {
  display: none;
}

.profile-picture-upload input {
  border: 1px solid #b7b7b7;
  padding: 5px 5px 5px 10px;
  font-size: 18px;
  transition: 0.2s;
}

.profile-picture-upload input:focus {
  outline: none;
  border: 1px solid #64d488;
}

.profile-picture-upload input::placeholder {
  color: #bebebe;
}

.accordion {
  background-color: #fff;
  cursor: pointer;
}

.accordion:hover {
  background-color: #fefefe;
}

.tcf-form-label {
  padding-top: 0.5rem;
  font-weight: bold;
}

.tcf-form-sub-label {
  padding-top: 0.5rem;
  font-weight: lighter;
}

@media (min-width: 576px) {
  .tcf-form-label {
    text-align: right;
  }
}

/* An attempt to fit more info into our tables.  Real estate is limited. */
.react-bootstrap-table th,
.react-bootstrap-table td {
  font-size: 0.9em;
  font-weight: normal;
}

.react-bootstrap-table .expand-cell {
  cursor: pointer;
}

.react-bootstrap-table .expand-cell-header {
  cursor: pointer;
}

.email-campaign-tab-dropdown .rw-dropdown-list-value {
  cursor: pointer;
}

/* BEGIN REACT BIG CALENDAR TWEAKS */

.rbc-event-content {
  font-size: 0.8rem;
}

.rbc-event-content p {
  margin-bottom: 0;
}

.rbc-agenda-view table.rbc-agenda-table tbody > tr > td {
  border-left: 1px solid #ddd;
}

.rbc-event .rbc-event-label {
  display: none;
}

.rbc-calendar {
  font-size: 0.9rem;
}

.rbc-agenda-view table.rbc-agenda-table .rbc-agenda-time-cell > span {
  text-transform: none;
}

.rbc-toolbar button:focus {
  outline: none !important;
  background-color: inherit !important;
  border-color: #ccc !important;
}

.rbc-toolbar button:active:hover,
.rbc-toolbar button:active:focus,
.rbc-toolbar button.rbc-active:hover,
.rbc-toolbar button.rbc-active:focus {
  background-color: #d4d4d4 !important;
  border-color: #8c8c8c !important;
}

/* END REACT BIG CALENDAR TWEAKS */

/* BEGIN ORGANIZATION SELF MANAGEMENT TABLE TWEAKS */

/* Force table to auto size which results in better column widths.  React bootstrap table overrides the
   default with "fixed" which, while better performing, really makes a mess of things. */
.org-self-manage .react-bootstrap-table table {
  table-layout: auto;
}

.org-self-manage table {
  border-bottom: 1px solid #dee2e6;
}

.org-self-manage table th,
.org-self-manage table td {
  font-size: 0.9em;
  font-weight: normal;
  padding: 0.3em;
}

.org-self-manage table td {
  cursor: pointer;
}

.org-self-manage table tr:hover {
  background-color: #f9f9ff;
}

/* END ORGANIZATION SELF MANAGEMENT TABLE TWEAKS */

.table-968 {
  min-width: 968px;
}

/* Experiments for page with left sidebar */

.sidebar {
  background-color: #f1f1f1;
  min-height: 70vh;
}

@media (max-width: 767px) {
  .sidebar {
    min-height: 0;
  }
}

.card-header {
  background-color: rgb(96, 113, 148); /*#9aafda;*/
  color: #fff;
  /*font-weight: bold;*/
}

.accordion.card-header:hover {
  background-color: #11195b;
  color: #fff;
}

.nav-link {
  padding: 0.25rem 0.75rem;
  color: #fff;
}

.nav-link.tcf-typeahead-option {
  color: #0056b3;
}

.nav-link.tcf-typeahead-option:hover {
  color: #fff;
  background-color: #11195b;
}

.nav-pills .nav-link.active,
nav-pills .show > .nav-link {
  background-color: #11195b;
}

.nav-link:hover {
  color: #ddf;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  background-color: rgb(96, 113, 148);
  color: #fff;
}

.nav-tabs .nav-link {
  background-color: #f1f1f1;
  color: black;
}

#antitrust-calendar {
  background-color: #11195b;
  margin-bottom: 1.5em;
  width: 100%;
}

.btn-secondary {
  background-color: #11195b;
  border-color: #11195b;
}

.btn-secondary:active,
.btn-secondary.active,
.show > .btn-secondary.dropdown-toggle {
  background-color: #11195b;
  border-color: #11195b;
}

.btn-secondary:hover {
  background-color: rgb(96, 113, 148);
  border-color: #11195b;
}

.bg-dark {
  background-color: #11195b !important;
}

/* Document list components */
.tcf-article-date {
  font-size: 0.75rem;
}

.tcf-article-title {
  font-size: 1rem;
  line-height: 1.3;
  margin-bottom: 0.25rem;
  text-align: justify;
  font-weight: bold;
}

.tcf-article-text {
  font-size: 0.9rem;
  line-height: 1.3;
  text-align: justify;
  text-justify: inter-word;
}

.square-buttons .nav-link {
  border-radius: 0 !important;
  padding-bottom: 0.35rem;
}

.hover-underline:hover {
  text-decoration: underline;
}

#pdf-container {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

mark {
  opacity: 0.3;
  background: #ffff00;
  color: transparent;
  white-space: pre;
  padding: 2px 0;
}
